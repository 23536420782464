<template>
  <div>
    <Header></Header>
    <div class="content">
      <!-- 轮播 -->
      <swiper :options="swiperOption" ref="mySwiper" v-if="bannerList.length>0">
        <swiper-slide v-for="(item, index) in bannerList" :key="index">
          <img :src="oss + item" class="img_qa" alt="" />
        </swiper-slide>
      </swiper>
      <!-- <img src="../assets/static/images/img_qa.png" class="img_qa" alt="" /> -->
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3 plr30">
          当前位置：<router-link to="/">首页</router-link>>
          <a href="javascript:;">帮助</a>
          <!-- <a href="javascript:;" v-if="is_type==1">帮助</a>
          <a href="javascript:;" v-else>常见问题</a> -->
        </div>
      </div>
      <div class="index">
        <!-- 常见问题 -->
        <!-- <div class="fs32 tc title" v-if="is_type==1">帮助</div>
        <div class="fs32 tc title" v-else>常见问题</div> -->
        <div class="fs32 tc title">帮助</div>
        <div v-if="data_list.length == 0 && isShowNavBarLoading == true">
          <div class="no_data">
            <img src="../assets/static/icon/z_nothing.png" class="z_nothing" alt="" />
            <span>暂无数据</span>
          </div>
        </div>
        <ul class="m1440 qa_list" v-else>
          <li :class="quesIndex == index ? 'show' : ''" @click="changeQues(index)" v-for="(item, index) in data_list"
            :key="index">
            <div class="ques flex-box flex-col-start">
              <div class="normal fs24">Q<span class="fs16">{{pageNum>1? (pageNum-1) * 10 +index+ 1:index+1}}</span></div>
              <div class="fs18 col3 pl15 flex-grow-1">{{ item.question }}</div>
            </div>
            <div class="ans flex-box flex-col-start">
              <div class="normal fs24">答: </div>
              <!-- <div class="normal fs24">A<span class="fs16">{{pageNum>1? (pageNum-1) * 10 +index+ 1:index+1}}</span></div> -->
              <div class="fs18 col3 pl15 flex-grow-1">{{ item.answer }}</div>
            </div>
          </li>
        </ul>
        <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Pagination from "../components/pagination.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    Pagination,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      is_type: 1,// 1=帮助,2=常见问题
      oss: "",
      bannerList:[],//轮播图
      swiperOption: {
        // loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      data_list: [],
      pageSize: 10, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      isShowNavBarLoading: false, // 是否加载完
      quesIndex: -1, //问题下标
    };
  },
  created() {
    this.is_type = this.$route.query.is_type ? this.$route.query.is_type : 1;
    this.oss = this.global.uploadOss;
    this.get_banner();
    this.get_list();
  },

  methods: {
    // 切换问题
    changeQues(index) {
      this.quesIndex = index;
    },
    //获取banner
    get_banner() {
      let s_data = {
        contentKeys: 'HELP'
      }
      this.$api.apiIndexBanner(s_data).then((data_res) => {
        //console.log(data_res)
        if (data_res.resCode == 0) {
          let data = data_res.root[0]
          console.log(data)
          if (data.pictureUrl) {
            data.pictureUrl = data.pictureUrl.split(",")
          }
          this.bannerList = data.pictureUrl
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },
    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_list();
    },
    // 获取列表
    get_list() {
      var that = this;
      let page = that.pageNum;
      let s_param = {
        page: page,
        limit: that.pageSize,
        type: 0
      }
      this.$api.apiQuestion(s_param).then((data_res) => {
        //console.log(data_res);
        if (data_res.resCode == 0) {
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var totalCount = data_res.totalCount;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            that.total = totalCount;
            that.data_list = data_res.root;
            that.isShowNavBarLoading = is_data;
          }
        } else {
          that.isShowNavBarLoading = true;
          that.$message.error(data_res.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.swiper-container {
  height: 40vh;
}

.swiper-container img {
  object-fit: cover;
  -o-object-fit: cover;
  height: 100%;
}
</style>
